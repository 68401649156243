<template>
  <div>
    <div class="text-right mb-1">
      <b-button
        variant="primary"
        pill
        @click="createDepartment"
        v-if="hasPermission('create_department')"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Create</span>
      </b-button>
    </div>

    <b-card>
      <b-row class="mb-1" align-v="center">
        <b-col md="7">
          <div v-if="searchType">
            <b-form-group
              label="Name"
              label-for="name"
              class="w-50"
              v-if="searchType.value === 1"
            >
              <b-input-group>
                <template #prepend> </template>
              </b-input-group>
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Department Name"
              />
            </b-form-group>
            <b-form-group
              label="Hospital"
              label-for="hospital"
              class="w-50"
              v-if="searchType.value === 2"
            >
              <v-select
                id="hospital"
                v-model="selectedHospital"
                :options="hospitals"
                placeholder="Select Hospital"
                label="name"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group label="Search Type" label-for="searchType">
            <v-select
              id="searchType"
              v-model="searchType"
              :options="searchTypes"
              placeholder="Search Type"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="departments"
        details-td-class="m-0 p-0"
        small
        v-if="hasPermission('read_department')"
        :per-page="0"
      >
        <template #custom-foot>
          <b-tr variant="light">
            <b-td :colspan="fields.length">
              <span class="font-weight-bolder">
                Total Rows: {{ totalItems }}
              </span>
            </b-td>
          </b-tr>
        </template>
        <template #cell(serial)="row">
          {{ (currentPage - 1) * perPage + row.index + 1 }}
        </template>
        <template #cell(name)="row">
          {{ truncate(row.item.name, 20) }}
        </template>
        <template #cell(into)="row">
          {{ truncate(row.item.intro, 20) }}
        </template>
        <template #cell(created_at)="row">
          {{ row.item.created_at }}
        </template>
        <template #cell(updated_at)="row">
          {{ row.item.updated_at }}
        </template>
        <template #cell(created_by_data)="row">
          {{ row.item.created_by }}
        </template>
        <template #cell(updated_by_data)="row">
          {{ row.item.updated_by }}
        </template>
        <template #cell(manage)="row">
          <b-button
            variant="info"
            pill
            size="sm"
            class="mr-1"
            @click="editDepartment(row.item)"
            v-if="hasPermission('update_department')"
          >
            Edit
          </b-button>
          <b-button
            variant="danger"
            pill
            size="sm"
            @click="removeDepartment(row.item)"
            v-if="hasPermission('delete_department')"
          >
            Delete
          </b-button>
        </template>
      </b-table>
      <b-pagination
        size="md"
        :total-rows="totalItems"
        v-model="currentPage"
        :per-page="perPage"
      ></b-pagination>
      <!-- <span class="mt-1 bg1 float-right" >Total Rows: {{ rows }}</span> -->
    </b-card>
    <department-create-modal
      @modalClosed="onModalClosed"
      :key="`create-${departmentCreateModalCount}`"
    />
    <department-edit-modal
      :department="department"
      @modalClosed="onModalClosed"
      :key="`edit-${departmentEditModalCount}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DepartmentCreateModal from "@/components/admin/department/DepartmentCreateModal.vue";
import DepartmentEditModal from "@/components/admin/department/DepartmentEditModal.vue";
import util from "@/util.js";

export default {
  components: {
    DepartmentCreateModal,
    DepartmentEditModal,
  },
  data() {
    return {
      fields: [
        { key: "serial", label: "S. No" },
        { key: "name", label: "Name" },
        { key: "intro", label: "Intro" },
        { key: "created_at", label: "Created At" },
        { key: "created_by_data", label: "Created By" },
        { key: "updated_at", label: "Created At" },
        { key: "updated_by_data", label: "Updated By" },
        { key: "manage", label: "Action" },
      ],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      departments: [],
      department: null,
      DepartmentCreateModal: 0,
      DepartmentEditModal: 0,
      departmentCreateModalCount: 0,
      departmentEditModalCount: 0,
      searchTypes: [
        { value: 1, name: "Name" },
        { value: 2, name: "Hospital" },
      ],
      searchType: null,
      name: "",
      selectedHospital: null,
      hospitals: [],
    };
  },
  mixins: [util],
  created() {
    this.searchType = this.searchTypes[0];
  },
  async mounted() {
    await this.fetchPaginatedData();
    await this.fetchHospitals();
  },
  methods: {
    ...mapActions({
      getDepartment: "appData/getDepartments",
      deleteDepartment: "appData/deleteDepartment",
      getHospitalsPublic: "appData/getHospitalsPublic",
    }),

    async search() {
      if (this.searchType) {
        switch (this.searchType.value) {
          case 1:
            this.selectedHospital = null;

            break;
          case 2:
            this.name = "";

            break;
        }
      } else {
        this.name = "";
        this.selectedHospital = null;
      }
      this.currentPage = 1;
      await this.fetchPaginatedData();
    },
    async fetchHospitals() {
      try {
        const res = await this.getHospitalsPublic();
        this.hospitals = res.data;
      } catch (error) {
        console.error("Error fetching hospitals:", error);
      }
    },
    async fetchPaginatedData() {
      try {
        const res = await this.getDepartment({
          pageNumber: this.currentPage,
          name: this.name,
          hospital: this.selectedHospital ? this.selectedHospital.id : null,
        });

        this.departments = res.data.results;
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },
    createDepartment() {
      this.centerCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("department-create-modal");
      });
    },
    editDepartment(department) {
      this.department = department;
      this.departmentEditModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("department-edit-modal");
      });
    },
    async removeDepartment(department) {
      this.department = department;

      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const res = await this.deleteDepartment({
              pk: this.department.id,
            });
            if (res.status === 200) {
              this.$swal({
                title: "Department deleted successfully",
                icon: "success",
              });
              if (
                (this.totalItems - 1) % this.perPage === 0 &&
                this.currentPage !== 1
              ) {
                this.currentPage -= 1;
              } else {
                await this.fetchPaginatedData();
              }
            }
          } catch (error) {
            this.show = false;
            this.displayError(error);
          }
        } else {
          this.show = false;
        }
      });
    },
    async onModalClosed() {
      await this.fetchPaginatedData();
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
    }),
    rows() {
      return this.departments.length;
    },
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
};
</script>

<style>
.bg1 {
  color: red;
  font-weight: normal;
}
</style>
