<template>
  <b-modal
    id="department-edit-modal"
    title="Department Edit Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Department Edit</h2>
    </template>
    <validation-observer ref="departmentEditFormValidation">
      <b-form @submit.prevent>
        <!-- Department Name -->
        <b-form-group label-for="name">
          <template #label>
            Department Name <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="name"
            v-model="name"
            v-restrict="regex"
            placeholder="Department Name"
            key="name"
            required
            @keydown="checkMaxLength($event, key, 200)"
          />
        </b-form-group>

        <!-- Intro -->
        <b-form-group label-for="intro">
          <template #label> Intro <span class="text-danger">*</span> </template>
          <b-form-textarea
            id="intro"
            v-model="intro"
            placeholder="Intro"
            rows="3"
            max-rows="6"
            key="intro"
            @keydown="checkMaxLength($event, key, 200)"
          ></b-form-textarea>
        </b-form-group>

        <!-- Description -->
        <b-form-group label-for="description">
          <template #label> Description </template>
          <quill-editor id="description" v-model="description" />
        </b-form-group>
        <b-form-group label-for="tags">
          <template #label> Department Tags </template>
          <b-form-input
            id="tags"
            v-model="tags"
            placeholder="Tags"
            required
            key="tags"
          />
        </b-form-group>

        <!-- Status -->
        <b-form-group label-for="status">
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="status"
            v-model="status"
            :options="options"
          ></b-form-select>
        </b-form-group>

        <!-- Existing Image -->
        <b-form-group
          class="form-group"
          label="Existing Image:"
          label-for="existingImage"
        >
          <img
            v-if="department && department.image"
            :src="department.image"
            alt="Existing Image"
            style="max-width: 5%; margin-bottom: 10px"
          />
        </b-form-group>

        <!-- File Input for Image -->
        <validation-provider v-slot="{ errors }" name="Image">
          <b-form-group
            class="form-group"
            id="input-group-2"
            label="Image:"
            label-for="input-2"
            description="Upload an image in SVG format."
            :invalid-feedback="errors[0]"
          >
            <b-form-file
              ref="imageInput"
              id="documents"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              plain
              accept=".svg"
              v-model="image"
              @change="validateFileType($event, 'image')"
            ></b-form-file>
          </b-form-group>
        </validation-provider>

        <!-- Existing Banner Image -->
        <b-form-group
          class="form-group"
          label="Existing Banner Image:"
          label-for="existingBannerImage"
        >
          <img
            v-if="department && department.display_image"
            :src="department.display_image"
            alt="Existing Banner Image"
            style="max-width: 10%; margin-bottom: 10px"
          />
        </b-form-group>

        <!-- File Input for Banner Image -->
        <validation-provider v-slot="{ errors }" name="DisplayImage">
          <b-form-group
            class="form-group"
            id="input-group-2"
            label="Banner Image:"
            label-for="input-2"
            description="Upload an image in PNG, JPG, or JPEG format."
            :invalid-feedback="errors[0]"
          >
            <b-form-file
              ref="displayImageInput"
              id="displayDocuments"
              placeholder="Choose a PNG, JPG, or JPEG file or drop it here..."
              drop-placeholder="Drop file here..."
              accept=".png,.jpg,.jpeg"
              plain
              v-model="displayFiles"
              @change="validateFileType($event, 'displayFiles')"
            ></b-form-file>
          </b-form-group>
        </validation-provider>

        <!-- Submit Button -->
        <b-form-group class="mt-2 text-right">
          <b-button
            type="submit"
            variant="info"
            pill
            class="mr-1"
            @click="submit"
          >
            Update
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  mixins: [util],
  props: {
    department: Object,
  },
  data() {
    return {
      required,
      name: "",
      intro: "",
      description: "",
      tags: "",
      image: null,
      displayFiles: null,
      status: "",
      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
      regex: /^[a-zA-Z ]*$/,
    };
  },
  async mounted() {
    if (this.department) {
      this.name = this.department.name;
      this.description = this.department.description;
      this.tags = this.department.tags;
      this.intro = this.department.intro;
      this.status = this.department.status;
    }
  },
  methods: {
    ...mapActions({
      updateDepartment: "appData/updateDepartment",
    }),
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    validateFileType(event, fileType) {
      const file = event.target.files[0];
      if (!file) return;

      if (fileType === "image") {
        const allowedTypes = ["image/svg+xml"];
        if (!allowedTypes.includes(file.type)) {
          event.target.value = null;
          if (this.$refs.imageInput) {
            this.$refs.imageInput.$el.setCustomValidity(
              "Please upload an SVG image file."
            );
            this.$refs.imageInput.$el.reportValidity();
          }
        } else {
          if (this.$refs.imageInput) {
            this.$refs.imageInput.$el.setCustomValidity("");
          }
        }
      } else if (fileType === "displayFiles") {
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
        if (!allowedTypes.includes(file.type)) {
          event.target.value = null;
          if (this.$refs.displayImageInput) {
            this.$refs.displayImageInput.$el.setCustomValidity(
              "Please upload a PNG, JPG, or JPEG image file."
            );
            this.$refs.displayImageInput.$el.reportValidity();
          }
        } else {
          if (this.$refs.displayImageInput) {
            this.$refs.displayImageInput.$el.setCustomValidity("");
          }
        }
      }
    },

    async submit() {
      try {
        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("intro", this.intro);
        formData.append("description", this.description);
        formData.append("tags", this.tags);
        formData.append("status", this.status);
        formData.append("updated_by", this.getLoggedInUser.id);
        if (this.image) {
          formData.append("image", this.image);
        }
        if (this.displayFiles) {
          formData.append("display_image", this.displayFiles);
        }

        const res = await this.updateDepartment({
          payload: formData,
          pk: this.department.id,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Department updated successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("department-edit-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.intro = "";
      this.description = "";
      this.tags = "";
      this.image = null;
      this.displayFiles = null;
      this.status = "";
      this.errorMessage = "";
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },
};
</script>

<style></style>
